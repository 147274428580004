<template>
    <div class="p-2 d-flex flex-fill">
        <div class="h1 text-center font-weight-bolder text-white m-auto" v-if="user.user_id == user_id">
            <div v-if="status == 'y'">
                Your book has been <div class="badge badge-success">Approved</div>
            </div>
            <div v-else-if="status == 'n'">
                Your book is rejected.
            </div>
            <div v-else>
                You have already book this schedule, please wait for vet confirmation via chat.
            </div>
            <div v-if="note && note != 'undefined' && note != 'null'">
                {{ note }}
            </div>
        </div>
        <div class="" v-else>

        </div>
    </div>
</template>

<script> 

import store from '@/store'

export default {
    computed: {
        user() {
            return store.state.auth.USER
        }
    },
    data(){
        return {
            status: this.$route.params.status,
            note: this.$route.params.note,
            user_id: this.$route.params.user_id,
        }
    }
}

</script> 